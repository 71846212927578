import React from 'react';
import { Box, Flex, Text, VStack, Icon } from '@chakra-ui/react';
import { AddIcon, TimeIcon, EmailIcon } from '@chakra-ui/icons';
import { motion } from "framer-motion";
const MotionBox = motion(Box);

const Step = ({ title, description, icon }) => {
  return (
    <MotionBox
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.5 }}
    >
      <Flex direction="column" align="center" p={5}>
        <Icon as={icon} w={10} h={10} mb={3} />
        <Text fontSize="xl" fontWeight="bold" mb={1}>
          {title}
        </Text>
        <Text textAlign="center">{description}</Text>
      </Flex>
    </MotionBox>
  );
};

const TimelineSection = () => {
  return (
    <VStack spacing={8} my={10}>
      <Step
        title="Add Your Location"
        description="Pinpoint your location in the app for precise directions."
        icon={AddIcon}
      />
      <Step
        title="Generate a Link"
        description="Set a duration and generate a secure, temporary link."
        icon={TimeIcon}
      />
      <Step
        title="Share with Friends"
        description="Send the link to friends or delivery services for easy navigation."
        icon={EmailIcon}
      />
    </VStack>
  );
};

export default TimelineSection;
