import React from "react";
import {
  Box,
  Container,
  Heading,
  Text,
  Button,
  SimpleGrid,
  Icon,
  VStack,
  HStack,
  Divider,
  Image,
  Link,
  Center,
} from "@chakra-ui/react";
import { FaMapMarkedAlt, FaUserFriends, FaMobileAlt } from "react-icons/fa";
import { Link as ScrollLink } from "react-scroll";
import Logo from "../assets/Logo.svg";
import TimelineSection from "./TimeLineSection";
import DownloadSection from "./DownloadSection";
import image1 from "../assets/fancy.svg";
import { motion } from "framer-motion";
const MotionBox = motion(Box);

const Feature = ({ title, icon, children }) => (
  <VStack spacing={3}>
    <Icon as={icon} w={10} h={10} color={"primary"} />
    <Heading size="md">{title}</Heading>
    <Text textAlign="center">{children}</Text>
  </VStack>
);

const TeamMember = ({ name, role, emoji }) => (
  <VStack spacing={2}>
    <Text fontSize="xl">{emoji}</Text>
    <Text fontWeight="bold">{name}</Text>
    <Text fontSize="sm">{role}</Text>
  </VStack>
);

const DabosLandingPage = () => {
  return (
    <MotionBox
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.5 }}
    >
      <Container maxW="container.xl">
        <VStack
          spacing={10}
          py={10}
          textAlign="center"
          backgroundColor={"hoverBg"}
        >
          <Image src={Logo} alt="Dabos Logo" boxSize="100px" />
          <Heading>Welcome to Dabos 📍</Heading>
          <Text fontSize="xl">Your Gateway to Efficient Location Sharing</Text>
          <ScrollLink to="about" smooth={true} duration={500}>
            <Button colorScheme="blue" size="lg">
              Get Started
            </Button>
          </ScrollLink>
        </VStack>

        <Center>
          <Image src={image1} alt="Dabos Logo" />
        </Center>

        <Divider my={10} />
        <SimpleGrid
          columns={{ base: 1, md: 3 }}
          spacing={10}
          id="about"
          backgroundColor={"hoverBg"}
        >
          <Feature title="Easy Location Sharing" icon={FaMapMarkedAlt}>
            Share locations with a simple link.
          </Feature>
          <Feature title="Connect with Friends" icon={FaUserFriends}>
            Seamlessly share and manage locations with friends.
          </Feature>
          <Feature title="Mobile Friendly" icon={FaMobileAlt}>
            Access Dabos on any device, anywhere.
          </Feature>
        </SimpleGrid>
        <Divider my={10} />
        <VStack spacing={5} backgroundColor={"hoverBg"}>
          <Heading>About Dabos</Heading>
          <Text fontSize="lg">
            Discover a new way to share and manage locations seamlessly.
          </Text>
        </VStack>
        <Divider my={10} />
      </Container>
      <Divider my={10} />
      <TimelineSection />
      <Divider my={10} />

      <VStack spacing={5} backgroundColor={"hoverBg"}>
        <Heading>Our Team</Heading>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={10}>
          <TeamMember
            name="Ahmad AlNasser"
            role="Head of Design and UI/UX Designer"
            emoji={"👨🏻‍🎨"}
          />
          <TeamMember
            name="Hadi AlMarzooq"
            role="CTO and Software Engineer"
            emoji={"👨🏻‍💻"}
          />
          <TeamMember
            name="Ahmed AlKhawaher"
            role="Front-End Developer Software Engineer"
            emoji={"👨🏻‍💻"}
          />
          <TeamMember
            name="Ali AlMarzooq"
            role="Back-End Developer Software Engineer"
            emoji={"👨🏻‍💻"}
          />
        </SimpleGrid>
      </VStack>

      <Divider my={10} />
      <DownloadSection />
    </MotionBox>
  );
};

export default DabosLandingPage;
