import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { createClient } from "@supabase/supabase-js";
import {
  Box,
  Text,
  Spinner,
  Image,
  Link,
  useColorModeValue,
  Container,
  VStack,
  HStack,
  Icon,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { FaMapMarkerAlt } from "react-icons/fa";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import InvalidLink from "./InvalidLink";
import LoadingWidget from "./LoadingWidget";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
const MotionBox = motion(Box);

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;
const supabase = createClient(supabaseUrl, supabaseAnonKey);

function LocationDetails() {
  const { token } = useParams();
  const [locationData, setLocationData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [imageUrl, setImageUrl] = useState(null);
  const cardBg = useColorModeValue("cardBg", "dark.cardBg");
  const hoverBg = useColorModeValue("hoverBg", "dark.hoverBg");
  const [isValidLink, setIsValidLink] = useState(true);

  const emojiIcon = L.divIcon({
    className: "custom-icon",
    html: "📍",
    iconSize: [20, 20],
  });

  useEffect(() => {
    async function fetchLocationData() {
      setLoading(true);

      const { data, error } = await supabase
        .from("temp_links")
        .select(
          `
          *,
          locations:location_id (
            *
          )
        `
        )
        .eq("link_token", token)
        .maybeSingle();
      if (data == null) {
        setIsValidLink(false);
        setLoading(false);

        return;
      }

      if (
        new Date(
          new Date(data.valid_until).setHours(
            new Date(data.valid_until).getHours() - 3
          )
        ) > new Date()
      ) {
        setIsValidLink(true);
      } else {
        setIsValidLink(false);
      }
      if (error) {
        console.error("Error fetching location data:", error);
      } else {
        setLocationData(data?.locations);
        if (data?.locations) {
          const imagePath = `https://ccqlipwickdiylwoptbd.supabase.co/storage/v1/object/public/location_images/${data.locations.user_id}/${data.locations.location_id}/img`;

          setImageUrl(imagePath);
        }
      }

      setLoading(false);
    }

    if (token) {
      fetchLocationData();
    }
  }, [token]);

  if (loading) {
    return <LoadingWidget />;
  }
  if (!isValidLink) {
    return <InvalidLink />;
  }
  if (!locationData) {
    return <Text>No location data found.</Text>;
  }

  const googleMapUrl = `https://maps.google.com/?q=${locationData.latitude},${locationData.longitude}`;

  return (
    <MotionBox
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      bg={cardBg}
      boxShadow="0px 4px 10px rgba(0, 0, 0, 0.15)"
      borderRadius="xl"
      p={6}
      mb={6}
      _hover={{ bg: hoverBg, transform: "scale(1.05)" }}
      transition="all 0.3s ease-in-out"
    >
      <Text fontSize="2xl" fontWeight="bold" mb={3}>
        Location Details
      </Text>

      {imageUrl && (
        <Image
          src={imageUrl}
          alt="Location"
          borderRadius="lg"
          objectFit="cover"
          mb={4}
          width="100%"
          htmlWidth="100%"
          htmlHeight="auto"
          boxShadow="0px 4px 8px rgba(0, 0, 0, 0.1)"
        />
      )}

      <VStack align="start" spacing={3}>
        <Text fontSize="lg" fontWeight="medium" color="gray.700">
          Description:
        </Text>
        <Text fontSize="md" color="gray.700">
          {locationData.description}
        </Text>
        <Text fontSize="lg" fontWeight="medium" color="gray.700">
          Notes from dabos'er:
        </Text>
        <Text fontSize="md" color="gray.700">
          {locationData.note}
        </Text>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          backgroundColor={"black"}
          borderRadius="lg"
        >
          <Link
            href={googleMapUrl}
            isExternal
            color="blue.500"
            fontSize="lg"
            padding={2}
            textUnderlineOffset={1}
          >
            <HStack spacing={2}>
              <Icon as={FaMapMarkerAlt} color="blue.500" />
              <Text>Open in Google Maps</Text>
            </HStack>
          </Link>
        </Box>
        <MapContainer
          center={[locationData.latitude, locationData.longitude]}
          zoom={13}
          style={{ height: "400px", width: "100%" }}
        >
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <Marker
            position={[locationData.latitude, locationData.longitude]}
            icon={emojiIcon}
          >
            <Popup>{locationData.description}</Popup>
          </Marker>
        </MapContainer>{" "}
      </VStack>
    </MotionBox>
  );
}

export default LocationDetails;
