import { FaExclamationCircle } from "react-icons/fa"; // import the icon you want to use
import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { motion } from "framer-motion";
const MotionBox = motion(Box);
const InvalidLink = () => (
  <MotionBox
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: -20 }}
    bg={"cardBg"}
    boxShadow="0px 4px 10px rgba(0, 0, 0, 0.15)"
    borderRadius="xl"
    p={6}
    my={6}
    textAlign="center"
  >
<Flex justifyContent="center" alignItems="center" >
  <FaExclamationCircle size="50px" color="red" />
</Flex>
    <Text fontSize="2xl" fontWeight="bold" mb={3} color="red.500">
      Invalid Link
    </Text>
    <Text fontSize="lg" mb={4}>
      The link you are trying to access is no longer valid.
    </Text>
    <Text>
      Please contact the person who sent you the link for more information or to
      request a new link.
    </Text>
  </MotionBox>
);

export default InvalidLink;
