// src/theme.js
import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  colors: {
    primary: '#4B89FF',
    secondary: '#8992A4',
    cardBg: '#FFFFFF',
    hoverBg: '#FAFAFA',
    focusBg: '#FFFFFF',
    scaffoldBg: '#FFFFFF',
    hintColor: '#C1C2C5',
    dark: {
      cardBg: '#444C52',
      hoverBg: '#303030',
      focusBg: '#FFFFFF',
      scaffoldBg: '#181818',
      hintColor: '#FFFFFF'
    }
  },
  fonts: {
    heading: 'IBM Plex Sans',
    body: 'IBM Plex Sans',
  },
  components: {
    // You can customize component styles here
  },
});

export default theme;
