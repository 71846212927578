import React from 'react';
import { Box } from '@chakra-ui/react';

const bounceAnimation = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh', // Full viewport height
  fontSize: '2rem', // Larger emoji size
  animation: 'bounce 0.6s infinite alternate'
};

const LoadingWidget = () => {
  return (
    <Box style={bounceAnimation}>
      <span role="img" aria-label="location emoji">📍</span>
    </Box>
  );
}

export default LoadingWidget;

// You need to add keyframes to your global CSS
