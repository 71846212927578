import React from "react";
import { ChakraProvider, Container, Box, HStack, Text} from "@chakra-ui/react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LocationDetails from "./components/LocationDetails";
import DabosLandingPage from "./components/DabosLandingPage";
import Header from "./components/Header"; // Import the Header component
import theme from "./theme";
import DemoPage from "./components/DemoPage";

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Header /> {/* Include the Header */}
        <Routes>
          <Route path="/demo" element={<DemoPage />} />
          <Route path="/" element={<DabosLandingPage />} />
          <Route
            path="/location/:token"
            element={
              <Container
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: 10,
                }}
              >
                <LocationDetails />
              </Container>
            }
          />
        </Routes>
        <footer>
          <Container maxW="container.xl">
            <HStack justifyContent="space-between">
              <Text>© 2023 Dabos. All rights reserved.</Text>
            </HStack>
          </Container>
        </footer>
      </Router>
    </ChakraProvider>
  );
}

export default App;
