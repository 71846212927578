import React from 'react';
import { Box, Flex, Text, Link, Spacer } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

const Header = () => {
  return (
    <Box bg="primary" color="white" p={4} borderRadius={{ tl: 0, tr: 0, br: 10, bl: 10 }}>
      <Flex align="center" maxW="container.xl" m="0 auto">
        <Text fontSize="xl" fontWeight="bold">
          <RouterLink to="/">Dabos 📍</RouterLink>
        </Text>
        <Spacer />
        <Flex align="center">
          <Link as={RouterLink} to="/" px={2}>
            Home 
          </Link>
          {/* Add more navigation links as needed */}
        </Flex>
      </Flex>
    </Box>
  );
};

export default Header;
