import React from "react";
import { Box, Button, HStack, Text } from "@chakra-ui/react";
import { FaGooglePlay, FaAppStoreIos } from 'react-icons/fa';

const DownloadSection = () => {
  return (
    <Box my={10} textAlign="center">
      <Text fontSize="2xl" fontWeight="bold" mb={4}>
        Get Dabos on Your Device
      </Text>
      <HStack spacing={4} justifyContent="center">
        <Button
          as="a"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaGooglePlay size="50px" />
        </Button>
        <Button
          as="a"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaAppStoreIos size="50px" />
        </Button>
      </HStack>
    </Box>
  );
};

export default DownloadSection;
