import React from "react";
import {
  Box,
  Heading,
  Text,
  Container,
  AspectRatio,
  VStack,
  Icon,
  useColorModeValue,
  Center,
  Image,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { FiVideo, FiUser, FiSettings, FiMonitor } from "react-icons/fi";
import { ListItem } from "@chakra-ui/react";
import team from "../assets/team.svg";
const MotionBox = motion(Box);

const DemoPage = () => {
  const bgColor = useColorModeValue("gray.100", "gray.700");
  const hoverBgColor = useColorModeValue("blue.100", "blue.700");
  const TeamConts = [
    "Hadi AlMarzooq - CTO | Backend and Web Development",
    "Ali AlMarzooq - Project Management and Backend Development",
    "Ahmad AlNasser - UI/UX Design And Head Of Design",
    "Ahmed AlKhawaher - Frontend Development",
  ];

  return (
    <Container maxW="container.xl" py={10}>
      <Heading as="h1" size="xl" textAlign="center" mb={10}>
        Dabos Project Demo 📍
      </Heading>

      <VStack spacing={10}>
        <Section
          title="1: Project Overview"
          presenter="Ali AlMarzooq"
          videoId="f22e18675dbe48498d7d4c46ac290492"
          icon={FiMonitor}
          bgColor={bgColor}
          hoverBgColor={hoverBgColor}
        />

        <Section
          title="2: UI/UX Overview"
          presenter="Ahmad AlNasser"
          videoId="9af2eb8d1c87474880ce850b1bcf5b43"
          icon={FiUser}
          bgColor={bgColor}
          hoverBgColor={hoverBgColor}
        />

        <Section
          title="3: Main Components and Backend Overview"
          presenter="Hadi AlMarzooq"
          videoId="d0bb8729cd664d9d9069577df2b8451d"
          icon={FiVideo}
          bgColor={bgColor}
          hoverBgColor={hoverBgColor}
        />

        <Section
          title="4: App Settings Overview"
          presenter="Ahmed AlKhawaher"
          videoId="fdbf0a15b1b246df8ff3552451864ca1"
          icon={FiSettings}
          bgColor={bgColor}
          hoverBgColor={hoverBgColor}
        />
      </VStack>

      <Box textAlign="center" mt={12}>
        <Center>
          <Image src={team} alt="Dabos Logo" />
        </Center>
      </Box>
    </Container>
  );
};

const Section = ({
  title,
  presenter,
  videoId,
  icon,
  bgColor,
  hoverBgColor,
}) => (
  <MotionBox
    whileHover={{ scale: 1.1 }}
    p={5}
    bg={bgColor}
    borderRadius="lg"
    shadow="md"
    transition="background-color 0.2s"
    _hover={{ bg: hoverBgColor }}
    width="80%" // set the width
    height="50%" // set the height
  >
    <Icon as={icon} w={6} h={6} mb={3} />
    <Heading as="h2" size="lg" mb={3}>
      {title}
    </Heading>
    <Text mb={4}>Presented by: {presenter}</Text>
    <AspectRatio ratio={16 / 9}>
      <iframe
        src={`https://www.loom.com/embed/${videoId}`}
        allowFullScreen
      ></iframe>
    </AspectRatio>
  </MotionBox>
);

export default DemoPage;
